<template>
  <div>
    <v-dialog v-model="onboardingDialog" width="1100">
      <v-card flat tile>
        <v-card-title class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            depressed
            icon
            large
            color="primaryred"
            @click="$store.dispatch('setOnboarding', false)"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-window
          v-model="onboardingIndex"
          show-arrows
          class="color-window-onboarding"
        >
          <template v-slot:[`prev`]="{ on, attrs }">
            <v-btn depressed icon v-bind="attrs" v-on="on">
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`next`]="{ on, attrs }">
            <v-btn depressed icon v-bind="attrs" v-on="on">
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
          </template>
          <v-window-item
            v-for="(onboardingItem, index) in getOnboardingItems"
            :key="`card-${index}`"
          >
            <v-card color="transparent">
              <v-row class="fill-height" align="center" justify="center">
                <v-card-text class="text-justify">
                  <v-container>
                    <v-row
                      class="mx-12 my-0"
                      v-if="!onboardingItem.Components[currentLanguage]"
                    >
                      <v-col cols="6" class="pr-6">
                        <v-img
                          v-if="getUserToken"
                          contain
                          height="400"
                          :lazy-src="
                            serverUrl +
                            '/images/onboarding/' +
                            onboardingItem.Images[currentLanguage] +
                            '?token=' +
                            getUserToken
                          "
                          :src="
                            serverUrl +
                            '/images/onboarding/' +
                            onboardingItem.Images[currentLanguage] +
                            '?token=' +
                            getUserToken
                          "
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>
                      <v-col cols="6" class="pl-6">
                        <p v-html="onboardingItem.Texts[currentLanguage]"></p>
                      </v-col>
                    </v-row>
                    <component
                      class="mx-12 my-0"
                      v-else
                      :is="onboardingComponent"
                    ></component>
                  </v-container>
                </v-card-text>
              </v-row>
            </v-card>
          </v-window-item>
        </v-window>
        <v-card-actions>
          <v-layout justify-center>
            <v-item-group
              v-model="onboardingIndex"
              class="text-center"
              mandatory
            >
              <v-item
                v-for="(n, index) in getOnboardingItems"
                :key="`btn-${index}`"
                v-slot="{ active, toggle }"
              >
                <v-btn
                  depressed
                  color="primary"
                  :input-value="active"
                  icon
                  @click="toggle"
                  x-small
                >
                  <v-icon>mdi-record</v-icon>
                </v-btn>
              </v-item>
            </v-item-group>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      serverUrl: this.$helpers.getServerUrl(),
    };
  },
  computed: {
    ...mapGetters({
      onboardingItems: "onboardingItems",
      onboardingDialog: "onboardingDialog",
      onboardingIndex: "onboardingIndex",
    }),
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    getUserToken() {
      return this.$auth.userToken();
    },
    getOnboardingItems() {
      if (!this.onboardingItems) {
        return [];
      }
      return this.onboardingItems;
    },
    onboardingComponent() {
      return () =>
        this.onboardingItems[this.onboardingIndex].Components[
          this.currentLanguage
        ]
          ? import(
              "@/components/onboarding/" +
                this.onboardingItems[this.onboardingIndex].Components[
                  this.currentLanguage
                ] +
                ".vue"
            )
          : null;
    },
  },
  methods: {
    next() {
      this.$store.dispatch("nextOnboardingIndex");
    },
    prev() {
      this.$store.dispatch("prevOnboardingIndex");
    },
  },
};
</script>
  
  <style>
</style>